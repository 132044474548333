import en from '@/i18n/en';
import ar from '@/i18n/ar';

export default defineI18nConfig(() => {
  return {
    seo: true,
    legacy: false,
    locale: 'ar',
    messages: {
      en,
      ar,
    },
  };
});
